// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = [ "output" ]

  connect() {
    console.log('Composer Controller connected.');
  }

  initialize() {
  //  const channelId = parseInt( this.data.get('channel') );
  //  console.log( channelId );
  }

  rig_composer_reply() {
    var parent_post = this.data.get("post-id");
    $("#composer-title").html("Replying to post #" + parent_post);
    $("#composer-form #parent_id").val( parent_post );
    //console.log( event.target );
    //console.log( event.currentTarget );
    // console.log( $(event.currentTarget) );
    // console.log( $(event.currentTarget).closest(".post-card-body") );
  }

  rig_composer_edit() {
    var parent_post = this.data.get("post-id");
    var channelId = this.data.get("channel-id");
    $("#composer-title").html("Editing post #" + parent_post);
    $("#composer-form #parent_id").val( parent_post );
    console.log( channelId );
    // console.log( event.currentTarget );
    // console.log( $(event.currentTarget) );
    // console.log( $(event.currentTarget).closest(".post-card-body") );

   $.post( {
     url: "/channels/" + channelId + "/load_editor/" + parent_post,
     headers: { 'X-CSRF-Token': AUTH_TOKEN }
   });
  }
    

  show_composer() {
    console.log('Show Composer called.');
//    if ( $("#composer-title").text().includes('Editing post #') ) {
//      console.log("... EDITING ...");
//      $("#insertions").addClass('hidden');
//    } else {
//      $("#insertions").addClass('hidden');
//    }
    $("#composer").addClass("animate__bounceInUp");
    $("#composer").removeClass('hidden');
    $("#compose-button").removeClass("animate__animated animate__bounceInUp");
    $("#compose-button").addClass("animate__animated animate__backOutDown");
    $("#composer-input").focus();
    // $("#composer-input").scrollIntoView();
    // $("#compose-button").addClass('is-hidden');
  }

  hide_composer() {
    // console.log('Hide Composer called.');
    $("#composer").removeClass("animate__bounceInUp");
    $("#composer").addClass("animate__backOutDown");
    $("#compose-button").addClass("animate__animated animate__bounceInUp");
    $("#composer").addClass('hidden');

    $("#composer-title").html("New Post");
    $("#composer-form #parent_id").val('');
    $("#compose-button").removeClass("hidden");
    $("#insertions").removeClass("hidden");
    $("#composer-input").text('');

    //$("#compose-button").removeClass('is-hidden');
    //$("#composer").addClass('is-hidden');
  }

  toggle_height() {
    $('#composer-input').toggleClass('h-32 h-hscreen');
    $('#zoomarrow').toggleClass('fa-angle-double-up fa-angle-double-down');
    $('#composer-input').focus();
  }

////   submit_post_new() {
//// 
////     console.log(">>> submit_post");
////     console.log( $('#composer-form').serialize() );
////     var body = $("#composer-input").val();
////     console.log( JSON.stringify({ body: body, parent_id: 0, file: $("#post_image").prop('files')[0], _method: 'post' }) );
//// 
//// var form = $('#composer-form')[0];
//// 
//// var data = new FormData(form);
//// 
//// console.log( data );
//// 
////         $.ajax({
////             type: "POST",
////             enctype: 'multipart/form-data',
////             url: "/channels/3/create_post",
////             data: data,
////             processData: false,
////             contentType: false,
//// //            cache: false,
//// //            timeout: 600000,
//// //            success: function (data) {
//// //
//// //                $("#result").text(data);
//// //                console.log("SUCCESS : ", data);
//// //                $("#btnSubmit").prop("disabled", false);
//// //
//// //            },
//// //            error: function (e) {
//// //
//// //                $("#result").text(e.responseText);
//// //                console.log("ERROR : ", e);
//// //                $("#btnSubmit").prop("disabled", false);
//// //
//// //            }
//// //        });
//// 
////     });
//// 
//// //     $( "#composer-form" ).submit(function (e) { 
//// //   e.preventDefault(); 
//// //);
//// //     $("#composer-input").val('');
////   }

/*
 * TODO: Cleanup, remove debug statements
 * TODO: Handle pinned composer and take-down 
 */

 submit_post() {
  console.log(">>> submit_post");
  var body = $("#composer-input").val();
  // console.log(body);
  var channelId = parseInt( this.data.get('channel') );
  var submitMode = parseInt( this.data.get('smode') );
  // console.log( channelId );

  // var postImage = $("#post_image").val();
  // console.log(postImage);
  // console.log( $("#post_image").prop('files') );
  // console.log( $("#post_image").prop('files')[0] );
  // console.log( JSON.stringify({ body: body, parent_id: 0, file: $("#post_image").prop('files')[0], _method: 'post' }) );

    var theForm = $('#composer-form')[0];

    var formData = new FormData(theForm);

// console.log( formData );

//   const formData = new FormData();
//       formData.append('body', body);
//       formData.append('file', postImage);
// console.log('----');
// console.log( formData.values() );
// console.log('----');

if ( submitMode == 'submit' ) {

$( "#composer-form" ).submit();

} else {

$.ajax({
  type: "POST",
  enctype: 'multipart/form-data',
  url: "/channels/" + channelId + "/create_post",
  data: formData,
  processData: false,
  contentType: false,
  success: function (data) {

   $("#composer-input").val('');
   $("#post_image").val('');
     // console.log("AFTER !!!!!!!!")
     // console.log(data);
     // console.log(data['url']);
     // console.log(data['foo']);
     if ( data.hasOwnProperty('url') ) { window.location = data['url'] }

   },
 error: function (e) {

   // console.log("AFTER !!!!!!!!")


 }


//    }, function( data ) {
//      console.log("AFTER !!!!!!!!")
//      $("#composer-input").val('');
//      // console.log("RETURNS: " + data);
//      $( "#kudo-count-" + citation_id).text( data );
//      $(the_target).removeClass("add-kudo-button");
//      $(the_target).addClass("has-text-grey-light");
//      $(the_target).removeData( "action" );
} );

}

/******

    $.post( {
      url: "/channels/" + channelId + "/create_post",
      headers: { 'X-CSRF-Token': AUTH_TOKEN },
      multipart: true,
      //dataType: "json",
      data: formData
      // data: { body: body }
      // data: JSON.stringify({ body: body, file: $("#post_image"), _method: 'post' })
      // data: JSON.stringify({ body: body, parent_id: 0, file: $("#post_image").prop('files'), _method: 'post' })
      //data: $("#composer").serialize(),

    }, function( data ) {
      $("#composer-input").val('');
//      // console.log("RETURNS: " + data);
//      $( "#kudo-count-" + citation_id).text( data );
//      $(the_target).removeClass("add-kudo-button");
//      $(the_target).addClass("has-text-grey-light");
//      $(the_target).removeData( "action" );
    } );

    *****/

  }

}
