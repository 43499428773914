// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
//import ApplicationController from './application_controller'

//import consumer from './consumer';
import CableReady from 'cable_ready';

export default class extends Controller {

  connect() {


    document.addEventListener('deed', this.notify)
    console.log('UI Test Controller iz connected.')
  }

  disconnect () {
    this.channel.unsubscribe()
    document.removeEventListener('deed', this.notify)
  }

  notify = event => {
    const { id, cost } = event.detail
    console.log('Dirty deed ${id} done ${cost}')
  }

  userslug() {
    // var actionPanel = $(event.currentTarget).find(".post-actions");
    console.log( 'uitest_controller#userslug called with:');
    console.log( $(event.currentTarget).val() );
  }

  gotasynctest() {
    console.log("GOT IT!");
    $("#uitest").html("GOT IT!");
  }

}
