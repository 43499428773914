// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("jquery");
import StickySidebar from 'sticky-sidebar';

import "controllers";

function importAll (r) { r.keys().forEach(r); }
importAll(require.context('../javascripts/', true, /\.js$/));

//import "ionicons/dist/ionicons/index.esm.js";
//import "ionicons/dist/ionicons/index.esm.js"
//import "ionicons/dist/ionicons/ionicons.esm.js"
//import "ionicons/dist/ionicons/ionicons.js";

import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

import '../images/lion-black.png'
import '../images/lion.jpg'
import '../images/defaultuser.jpg'
import '../images/systemuser.png'
import '../images/entropic.png'
import '../images/profile_header.jpg'
require.context('../images', true)

// import bulmaSlider from 'bulma-slider'

require("trix")
require("@rails/actiontext")

// import '../../components/channel_panel_component/channel_panel_component.js';

// import 'alpinejs'
import "stylesheets/application.scss"

import "@hotwired/turbo-rails"
import * as Turbo from "@hotwired/turbo"

import '../../components/subnav_component/subnav_component.js';

/* ---- WINGATEUI ASSETS ---- */

/* import '../../../vendor/wingateui/app/components/foo_component/foo_component.js'; */

