// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Channel Controller connected.')
  }

  go_to_post() {
    console.log("GTP")

    var threadId = parseInt( $(event.currentTarget).data('threadid') );
    var threadSlug = $(event.currentTarget).data('threadslug');

    var userSlug = this.data.get("user-slug");
    var userSlug = $(event.currentTarget).data('user-slug');

    console.log(userSlug);
    console.log(threadId);
    console.log(threadSlug);
    event.preventDefault();
    window.location.assign("/b/" + userSlug + "/" + threadSlug);

  }

  go_to_link() {
    var theLink = $(event.currentTarget).data('href');
    event.preventDefault();
    console.log("IN GO TO LINK")
    console.log(theLink);
  }

}
