// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Channel Controller connected.')
  }

  // data-action="mouseover->channel#show_post_actions mouseout->channel#hide_post_actions"

  show_post_actions() {
    var actionPanel = $(event.currentTarget).find(".post-actions");
    actionPanel.removeClass('hidden');
    var threadId = parseInt( $(event.currentTarget).data('threadid') );
    if ( threadId != 0 ) {
      $(".thread-" + threadId).addClass('threadlight');
    }
  }

  hide_post_actions() {
    var actionPanel = $(event.currentTarget).find(".post-actions");
    actionPanel.addClass('hidden');
    var threadId = parseInt( $(event.currentTarget).data('threadid') );
    if ( threadId != 0 ) {
      $(".thread-" + threadId).removeClass('threadlight');
    }
  }

  toggle_post_actions() {

    var actionPanel = $(event.currentTarget).find(".post-actions");
    var timelineThread = this.data.get("threadid");
    var threadId = parseInt( $(event.currentTarget).data('threadid') );
    var postId = parseInt( $(event.currentTarget).data('postid') );

    // Only do something if in a thread
    if ( threadId != 0 ) {

      console.log( timelineThread );
      $("#timeline").find(".post-actions").addClass('hidden');

      // If we're not already selecting, then select
      if ( timelineThread == 0 ) {
        actionPanel.removeClass('hidden');
        this.data.set("threadid", threadId);
        $( "article" ).not( ".thread-" + threadId ).addClass('threadfade');
        $(".thread-" + threadId).find(".post-actions").removeClass('hidden');
        $(".system-card").addClass('hidden');
      } else {
        actionPanel.addClass('hidden');
        this.data.set("threadid", 0);
        $( "article" ).not( ".thread-" + threadId ).removeClass('threadfade');
        $(".thread-" + threadId).find(".post-actions").addClass('hidden');
        $(".system-card").removeClass('hidden');
      } 

    } else {
      // console.log( timelineThread );
      // console.log( postId );
      // console.log( ( "#post-" + postId ));
      if ( timelineThread == 0 ) {
        $("#timeline .post-card").not( "#post-" + postId ).find(".post-actions").addClass('hidden');
        actionPanel.toggleClass('hidden');
      }
    }

  }



}
