// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Utility Controller connected.')
  }

  // via: https://stackoverflow.com/questions/386281/how-to-implement-select-all-check-box-in-html
  toggle_checks() {
    // console.log("-> toggle_checks");
    // console.log( $(event.currentTarget).data('checked') );
    var is_checked = $(event.currentTarget).data('checked');

    if ( is_checked ) {
      $(event.currentTarget).data('checked', false);
    } else {
      $(event.currentTarget).data('checked', true);
    }
    $(':checkbox').each(function() {
      this.checked = !is_checked;                        
    });

    // console.log("<-toggle_checks");
  }


}
