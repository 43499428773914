// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Journal Controller connected.')
  }

  toggle_panel() {
    $('#journal_channel_panel').toggleClass('is-hidden');
    $('#toggle-arrow').toggleClass('fa-chevron-down');
    $('#toggle-arrow').toggleClass('fa-chevron-up');
  }

//  user_search() {
//    var searchFor = $("#search_field").val();
//    if (searchFor === "") {
//      window.location.assign("/moderator/users");
//    } else {
//      window.location.assign("/moderator/users?s=" + searchFor);
//    }
//
//  }


}
