// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Inclinations Controller connected.');
  }

  change() {
    console.log('Inclinations#Change called.');
    var sliderTable = $(event.target).closest('table');

    // console.log( "CUR VALUE: " + $(event.target).val() );
    // console.log( "OLD VALUE: " + $(event.target).attr('oldvalue') );
    // console.log( "MAX TOTAL: " + sliderTable.data('maxtotal') );
    // console.log( "CUR TOTAL: " + sliderTable.data('curtotal') );

    var maxTotal = parseInt( sliderTable.data('maxtotal') );
    var curTotal = parseInt( sliderTable.data('curtotal') );
    var incKey = parseInt( sliderTable.data('inckey') );
    var deltaSpace = maxTotal - curTotal;

    var oldValue = parseInt( $(event.target).attr('oldvalue') );
    var newValue = parseInt( $(event.target).val() );
    var deltaValue = newValue - oldValue;

    // console.log( "DELTA VALUE: " + deltaValue );
    // console.log( "DELTA SPACE: " + deltaSpace );

    // If we try to add more than is available, then clip it at the limit
    if ( deltaValue >= deltaSpace ) {
      deltaValue = deltaSpace;
      newValue = oldValue + deltaSpace;
      $(event.target).val(newValue);
      // console.log( "ADJUSTED DELTA VALUE: " + deltaValue );
      // console.log( "ADJUSTED VALUE: " + newValue );
    }


    var incVal = parseInt( $(event.target).attr('incval') );
    $("#sliderValue_" + incVal).val(newValue);

    if (deltaValue == 0) {
      // No change, so do nothing
    } else if (deltaValue < 0) {
      // Value went down so subtract
      sliderTable.data('curtotal', Math.max(curTotal + deltaValue, 0));
      $(event.target).attr('oldvalue', newValue);
    } else {
      // Value went up so clip and add
      sliderTable.data('curtotal', Math.min(curTotal + deltaValue, maxTotal));
      $(event.target).attr('oldvalue', newValue);
    }

    // var sliderTicker = $(event.target).closest(".ticker");
    // console.log( sliderTicker );
    // sliderTicker.text( maxTotal - parseInt(sliderTable.data('curtotal')) );
    // sliderTicker.text( 'huh' );
    $("span#ticker-" + incKey).text( maxTotal - sliderTable.data('curtotal') );
    // console.log("=> CUR TOTAL: " + parseInt( sliderTable.data('curtotal') ));
  }

}
