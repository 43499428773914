// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// TODO: Don't load downloads, pages, or settings unless tab is selected (use a Reflex)

import { Controller } from "stimulus"
// import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  initialize() {
  //  setupTopbarPinger();
  }

  connect() {
    // useClickOutside(this, {element: $('#user_faves_menu')})

    console.log('Topbar Controller connected.')
  }

  disconnect() {
    console.log('Topbar Controller disconnect.')
  }

//  clickOutside(event) {
//    // example to close a modal
//    event.preventDefault()
//    $(".topbar_dropdown").addClass('hidden');
//  }

  timeline_to_top() {
    $("#post-top").get(0).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  show_user_lists() {
    if ( $('#user_lists_menu').hasClass('hidden') ) {
      $(".topbar_dropdown").addClass('hidden');
      $("#user_lists_menu").removeClass('hidden');
    } else {
      // console.log("HIDE");
      $(".topbar_dropdown").addClass('hidden');
      $("#user_lists_menu").addClass('hidden');
    } 
  }

  show_user_faves() {
    if ( $('#user_faves_menu').hasClass('hidden') ) {
      $(".topbar_dropdown").addClass('hidden');
      $("#user_faves_menu").removeClass('hidden');
    } else {
      // console.log("HIDE");
      $(".topbar_dropdown").addClass('hidden');
      $("#user_faves_menu").addClass('hidden');   
    }   
  }

  show_user_controls() {
    $(".topbar_dropdown").addClass('hidden');
    $("#user_controls_menu").removeClass('hidden');
  }

  hide_all_dropdowns() {
    console.log('HIDE ALL');
    $(".topbar_dropdown").addClass('hidden');
  }

  show_mainmenu() {
    $("#mainmenu_outer").removeClass('hidden');
  }

}

// var topPingerID;
// 
// function setupTopbarPinger() {
//   if ( window.topPingerID) {
//     //console.log("IT IS DEFINED");
//   } else {
//     window.topPingerID = setInterval(function() {
//     //console.log('PING!')
//     var baseurl = window.location.origin;
// 
//     $.get( baseurl + "/pinger/topbar", function( data ) {
//       // console.log('PINGED!!!! ... ' + PINGER_REFRESH)
//       // $( ".result" ).html( data );
//       // alert( "Load was performed." );
//     });
// 
//     }, PINGER_REFRESH * 1000); 
//     //console.log(window.topPingerID);
//   }
// }
