// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// TODO: Don't load downloads, pages, or settings unless tab is selected (use a Reflex)

// import { Controller } from "stimulus"
import ApplicationController from './application_controller'

// export default class extends Controller {
export default class extends ApplicationController {

  connect() {
    super.connect();
    console.log('ctxbar Controller connected.')
  }

  timeline_to_top() {
    $("#post-top").get(0).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  show_settings() {
    // $("#ctxbar-tabs a.has-text-danger").addClass('has-text-grey');
    // $("#ctxbar-tabs a.has-text-danger").removeClass('has-text-danger');
    // $("#ctxbar-settings-btn").removeClass('has-text-grey');
    // $("#ctxbar-settings-btn").addClass('has-text-danger');
// 
    // $('#channel-panel').addClass('is-hidden');
    // $("#channel-pages").addClass('is-hidden');
    // $('#channel-downloads').addClass('is-hidden');
    // $('#channel-settings').removeClass('is-hidden');

    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');

    console.log('show_settings');
  }

  show_downloads() {
    // $("#ctxbar-tabs a.has-text-danger").addClass('has-text-grey');
    // $("#ctxbar-tabs a.has-text-danger").removeClass('has-text-danger');
    // $("#ctxbar-downloads-btn").removeClass('has-text-grey');
    // $("#ctxbar-downloads-btn").addClass('has-text-danger');
// 
    // $('#channel-panel').addClass('is-hidden');
    // $("#channel-pages").addClass('is-hidden');
    // $('#channel-downloads').removeClass('is-hidden');
    // $('#channel-settings').addClass('is-hidden');

event.preventDefault();

    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');

    console.log('show_downloads');
  }

  show_pages() {
    $(".ctxbar-btn").removeClass('active');
    $("#ctxbar-pages-btn").addClass('active');
 
    // $('#channel-panel').addClass('is-hidden');
    // $("#channel-pages").removeClass('is-hidden');
    // $('#channel-downloads').addClass('is-hidden');
    // $('#channel-settings').addClass('is-hidden');

    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');

    console.log('show_pages');
  }

  show_channelpanel() {
    $(".ctxbar-btn").removeClass('active');
    $("#ctxbar-channelpanel-btn").addClass('active');

//
//    $('#channel-panel').removeClass('is-hidden');
//    $("#channel-pages").addClass('is-hidden');
//    $('#channel-downloads').addClass('is-hidden');
//    $('#channel-settings').addClass('is-hidden');
//
    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');
    console.log('show_channelpanel');
  }

  show_slidebar() {
    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');
    console.log('show_slidebar');
  }

  hide_slidebar() {
    $('#slidebar').addClass('hidden');
    console.log('hide_slidebar');
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    console.log('---- SUCCESS ----');
  }

}
