$(document).on('turbolinks:load', function() {

$(function(){
  // bind change event to select
  $('.subnav_select').on('change', function () {
    console.log("HELLO?");
      var url = $(this).val(); // get selected value
    console.log(url);
      if (url) { // require a URL
          window.location = url; // redirect
      }
      return false;
  });
});

});