import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  connect() {
    console.log('==== connect clicksOutside ====')
    useClickOutside(this)
  }

//  clickOutside(event) {
//    // example to close a modal
//    event.preventDefault()
//    console.log("OUTSIDE")
//    console.log($(event.currentTarget).id)
//    //$(".topbar_dropdown").addClass('hidden');
////    $(event.currentTarget).addClass('hidden');
////    $(this).addClass('hidden');
////    $('#user_faves_menu').addClass('hidden');
//  }

  close_faves(event) {
    event.preventDefault()
    if ( !$('#user_faves_menu').hasClass('hidden') ) {
      console.log("IS OPEN")
      $('#user_faves_menu').addClass('hidden');
    }
  }

  close_lists(event) {
    event.preventDefault()
    if ( !$('#user_lists_menu').hasClass('hidden') ) {
      console.log("IS OPEN")
      $('#user_lists_menu').addClass('hidden');
    }
  }

  close_controls(event) {
    event.preventDefault()
    if ( !$('#user_controls_menu').hasClass('hidden') ) {
      console.log("IS OPEN")
      $('#user_controls_menu').addClass('hidden');
    }
  }

}
