// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// TODO: Don't load downloads, pages, or settings unless tab is selected (use a Reflex)

import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect();
    console.log('slidepanel Controller connected.')
  }

  settings() {
    console.log('settings');
  }

  downloads() {
    console.log('downloads');
    event.preventDefault();
  }

  pages() {
    console.log('pages');
  }

  filters() {
    console.log('filters');
  }

  show_slidebar() {
    $('#slidebar').removeClass('hidden');
    $('#slideover').removeClass('hidden');
    console.log('show_slidebar');
  }

  hide_slidebar() {
    $('#slidebar').addClass('hidden');
    console.log('hide_slidebar');
  }

  overlay_hide_slidebar() {
    console.log('overlay_hide_slidebar');
    var clickTarget = event.target.id;
    // console.log("clickTarget = " + clickTarget);
    // if ((clickTarget == 'slidebar') || (clickTarget == 'slidebar_overlay')) { this.hide_slidebar(); }
    if ( (clickTarget == 'slidebar_overlay') ) { this.hide_slidebar(); }
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    console.log('---- SUCCESS ----');
    this.show_slidebar();
  }

  beforeReflex (element, reflex) {
    // this.show_slidebar();
  }

}
