// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Forum Controller connected.')
  }

  show_topic() {
    var threadId = parseInt( $(event.currentTarget).data('threadid') );
    var channelSlug = $(event.currentTarget).data('forumid');
    console.log(threadId);
    console.log(channelSlug);
    window.location.assign("/f/" + channelSlug + "/" + threadId);
  }

//  show_post_actions() {
//    var actionPanel = $(event.currentTarget).find(".post-actions");
//    actionPanel.removeClass('is-hidden');
//    var threadId = parseInt( $(event.currentTarget).data('threadid') );
//    if ( threadId != 0 ) {
//      $(".thread-" + threadId).addClass('threadlight');
//    }
//  }
//
//  hide_post_actions() {
//    var actionPanel = $(event.currentTarget).find(".post-actions");
//    actionPanel.addClass('is-hidden');
//    var threadId = parseInt( $(event.currentTarget).data('threadid') );
//    if ( threadId != 0 ) {
//      $(".thread-" + threadId).removeClass('threadlight');
//    }
//  }

}
