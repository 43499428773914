// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// TODO: Don't load downloads, pages, or settings unless tab is selected (use a Reflex)

import { Controller } from "stimulus"
// import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  initialize() {
    setupMainmenuPinger();
  }

  connect() {
    // useClickOutside(this, {element: $('#user_faves_menu')})
    console.log('Mainmenu Controller connected.')
  }


  hide_mainmenu() {
    $("#mainmenu_outer").addClass('hidden');
  }

  show_user_controls() {
    // console.log("KLIK");
    if ( $('#user_controls_menu').hasClass('hidden') ) {
      $(".topbar_dropdown").addClass('hidden');
      $("#user_controls_menu").removeClass('hidden');
    } else {
      // console.log("HIDE");
      $(".topbar_dropdown").addClass('hidden');
      $("#user_controls_menu").addClass('hidden');      
    }
  }

}

var mainPingerID;

function setupMainmenuPinger() {
  if ( window.mainPingerID) {
    console.log("IT IS DEFINED");
  } else {
    window.mainPingerID = setInterval(function() {
    console.log('PING!')

     if ( CHANNEL_SLUG != '') {
      var baseurl = window.location.origin;
       $.get( baseurl + "/pinger/mainmenu?id=" + CHANNEL_SLUG, function( data ) {
         // console.log('MAIN MENU PINGED!!!! ... ' + baseurl + "/pinger/mainmenu?id=" + CHANNEL_SLUG);
         // $( ".result" ).html( data );
         // alert( "Load was performed." );
       });
     } else {
      var baseurl = window.location.origin;
       $.get( baseurl + "/pinger/mainmenu", function( data ) {
         // console.log('MAIN MENU PINGED!!!! ... ' + baseurl + "/pinger/mainmenu?id=" + CHANNEL_SLUG);
         // $( ".result" ).html( data );
         // alert( "Load was performed." );
       });

     }

    //}, 5 * 1000); 
    }, PINGER_REFRESH * 1000); 
    // console.log(window.mainPingerID);
  }
}